import {
  TextField,
  Icon,
  TextFieldProps,
  DatePicker,
  DatePickerProps,
  Popover,
  Button,
  Select,
  SelectOption,
  ButtonProps,
  Text,
} from "@shopify/polaris";
import { useCallback, useEffect, useState, VFC } from "react"
import { format } from "date-fns"
import { CalendarIcon } from "@shopify/polaris-icons";
import { Spacer } from "../../../entrypoints/admin/components"
import { t } from "i18next"

type DateTimeTextFieldProps = Omit<TextFieldProps, "autoComplete"> & {
  initialMonth: DatePickerProps["month"]
  initialYear: DatePickerProps["year"]
  disableDatesAfter?: DatePickerProps["disableDatesAfter"]
  disableDatesBefore?: DatePickerProps["disableDatesBefore"]
}

const times = [...Array(24)].flatMap((_, index) => {
  const hour = String(index).padStart(2, "0")
  return [`${hour}:00`, `${hour}:30`]
})

type StrictOption = Exclude<SelectOption, string>

export const DateTimeTextField: VFC<DateTimeTextFieldProps> = ({
  initialMonth,
  initialYear,
  onChange,
  onBlur,
  onFocus,
  value,
  disableDatesAfter,
  disableDatesBefore,
  ...otherProps
}) => {
  const [popoverActive, setPopoverActive] = useState(false)
  const [{ month, year }, setYearMonth] = useState<{ year: number, month: number }>({
    year: initialYear,
    month: initialMonth
  })

  const _date = value ? new Date(value) : undefined
  const _time = _date && format(_date, 'HH:mm')

  const [time, setTime] = useState<string>()
  const [date, setDate] = useState<Date>()

  useEffect(() => {
    setTime(_time)
    setDate(_date)
  }, [popoverActive])

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  )
  const handleTextChange: TextFieldProps["onChange"] = useCallback(
    (val, id) => onChange?.(val, id),
    []
  )
  const handleTextBlur: TextFieldProps["onBlur"] = useCallback(
    () => onBlur?.(),
    []
  )
  const handleTextFocus: TextFieldProps["onFocus"] = useCallback(
    () => {
      onFocus?.()
      setPopoverActive(true)
    }, []
  )
  const handleMonthChange: NonNullable<DatePickerProps["onMonthChange"]> = useCallback(
    (month, year) => setYearMonth({ month, year }),
    []
  )
  const handleDateChange: NonNullable<DatePickerProps["onChange"]> = useCallback(
    (date) => setDate(date.start),
    []
  )
  const handleOnClickDone:
  ButtonProps['onClick'] = useCallback(
    () => {
      if (!date || !time) return
      const dateString = format(date, 'yyyy-MM-dd')
      handleTextChange(`${dateString} ${time}`, "")
      setPopoverActive(false)
    }, [date, time]
  )

  const options: StrictOption[] = times.map((time) => {
    return { label: `${time}`, value: time }
  })

  return (
    <Popover
      activator={
        <TextField
          prefix={<Icon source={CalendarIcon} />}
          autoComplete="off"
          onChange={handleTextChange}
          value={value}
          onBlur={handleTextBlur}
          onFocus={handleTextFocus}
          {...otherProps}
        />
      }
      active={popoverActive}
      onClose={togglePopoverActive}
    >
      <Popover.Section>
        <Text variant="headingMd" as="h2">{t('Date')}</Text>
        <Spacer height={12} />
        <DatePicker
          year={year}
          month={month}
          onMonthChange={handleMonthChange}
          onChange={handleDateChange}
          selected={date}
          disableDatesAfter={disableDatesAfter}
          disableDatesBefore={disableDatesBefore}
        />
      </Popover.Section>
      <Popover.Section>
        <Text variant="headingMd" as="h2">{t('Time')}</Text>
        <Spacer height={12} />
        <Select
          label={''}
          placeholder={t("Please Select")}
          options={options}
          value={time}
          onChange={(v) => setTime(v)}
        />
      </Popover.Section>
      <Popover.Section>
        <Button
          fullWidth
          onClick={handleOnClickDone}
          disabled={!(Boolean(date) && Boolean(time))}
        >
          {t('Done')}
        </Button>
      </Popover.Section>
    </Popover >
  );
}
